<script>
/**
 * Navbar component
 */

import store from '@/store/store'

import {
  ShoppingCartIcon,MoonIcon,SunIcon,
} from "vue-feather-icons";


export default {
  components: {
    ShoppingCartIcon,MoonIcon,SunIcon
  },
  data() {
    return {

      tokenIsValid: 'yes',

      darkTheme: true,

      isCondensed: false,
      hover:false,

      numberOfResumesViewed:0,
      days_to_expiration:0,
      numberOfResumesInCart:0,
      total_balance: 0,

      in_sql: {
        user_id: 0,
        anonymous_id:0,
        dark_theme:0,
        isLoggedIn:0,
      },
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
    resumeId: {
      type: Number,
    }
  },



  mounted: () => {
    /*
    window.onscroll = function () {
      onwindowScroll();
    };
    function onwindowScroll() {
      if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }
      if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }
    */





    var links = document.getElementsByClassName("side-nav-link-ref");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }
    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }




  },

  created() {
    //this.$cookies.set('showAccountConfirmAlert',false);
    //store.commit('Update_showAccountConfirmAlert_State',this.$cookies.get('showAccountConfirmAlert'));





    console.log('nav rec theme is : ');
    let themeVar = document.getElementById("theme-opt").toString();

    if (themeVar.includes('dark.css')) {
      this.darkTheme = true;
    }

    console.log('inside mounted navbar rec');
    let token_str_flag = this.$cookies.get('access_token_skillrank');
    console.log('inside navbar: ' + token_str_flag);
    if (token_str_flag!== null) {
      console.log('token:')
      console.log(store.state.isLoggedIn)
      store.commit('UPDATE_TOKEN', this.$cookies.get("access_token_skillrank"));
      store.commit('UPDATE_USERNAME', this.$cookies.get("username_skillrank"));
      store.commit('UPDATE_USER_ID', this.$cookies.get("id_skillrank"));
      store.commit('Update_showAccountConfirmAlert_State', this.$cookies.get("showAccountConfirmAlert"));
      store.commit('UPDATE_LOGGEDIN_STATE', 1);
      /*if (this.$cookies.get("numberOfResumesViewed")!== null) {
        this.numberOfResumesViewed = this.$cookies.get("numberOfResumesViewed");
      }
      if (this.$cookies.get("expiration_date_milis")!== null) {
        this.expiration_date_milis = this.getDaysToExpiration(this.$cookies.get("expiration_date_milis"));
      }*/
    }



    this.$store.dispatch('record_event',
        {'browser_var': this.$browserDetect,'axios_var': this.axios,'root_var':this.$hostname,'cookies_var':$cookies,
          'actionType': 'open_page', 'clickedObjectId': ''});
      window.addEventListener('beforeunload', e => this.beforeunloadHandler(e));
      window.addEventListener('unload', e => this.unloadHandler(e));

      /*
      this.getResumeViews();
      this.getTotalBalance();
      this.getNumberOfResumes();
      this.getNumberOfVacancies();
      */
    if (this.$cookies.isKey('darkTheme')) {
      console.log('isKey check')
      if ( this.$cookies.get('darkTheme') === 'false') {
        console.log('isKey false')
        document.getElementById("theme-opt").setAttribute("href", "/css/style.css");
        document.getElementById("color-opt").setAttribute("href", "/css/colors/default.css");
      } else  {
        console.log('isKey not false')
      }
    }


   this.checkTokenValidity();
/*
    this.in_sql.user_id = store.state.user_id;
    this.in_sql.anonymous_id = store.state.anonymous_id;
    this.in_sql.isLoggedIn = store.state.isLoggedIn;

    this.axios({
      method: 'post',
      url: this.$hostname + '/dark-theme-get?access_token=' + store.state.token,
      data: this.in_sql,
    }).then((res) => {
      if (res.data.dark_theme === 0) {
        this.darkTheme = true;
        document.getElementById("theme-opt").setAttribute("href", "./css/style-dark.css");
        document.getElementById("color-opt").setAttribute("href", "./css/colors/cyan.css");
      } else {
        this.darkTheme = false;
        document.getElementById("theme-opt").setAttribute("href", "./css/style.css");
        document.getElementById("color-opt").setAttribute("href", "./css/colors/default.css");

      };
    });
*/


  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },


  methods: {

    goToPayment() {
      this.$router.push({path:'/buy'});
    },

      setDark() {
        console.log('inside setDark')
        console.log(document.getElementById("theme-opt"));

        document.getElementById("theme-opt").setAttribute("href", "/css/style-dark.css");
        document.getElementById("color-opt").setAttribute("href", "/css/colors/cyan.css");
        this.darkTheme = true;

        this.in_sql.user_id = store.state.user_id;
        this.in_sql.anonymous_id = store.state.anonymous_id;
        this.in_sql.dark_theme = 0;

        this.axios({
          method: 'post',
          url: this.$hostname + '/dark-theme-update?access_token=' + store.state.token,
          data: this.in_sql,
        }).then((res) => {
          this.$cookies.set('darkTheme',true);
        });


      },
      setLight() {
        console.log('inside setLight')
        console.log(document.getElementById("theme-opt"));

        document.getElementById("theme-opt").setAttribute("href", "/css/style.css");
        document.getElementById("color-opt").setAttribute("href", "/css/colors/default.css");
        this.darkTheme = false;


        this.in_sql.user_id = store.state.user_id;
        this.in_sql.anonymous_id = store.state.anonymous_id;
        this.in_sql.dark_theme = 1;

        this.axios({
          method: 'post',
          url: this.$hostname + '/dark-theme-update?access_token=' + store.state.token,
          data: this.in_sql,
        }).then((res) => {
          this.$cookies.set('darkTheme',false);
        });

      },



    getTotalBalance() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/payment/get-account-status?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        console.log('inside getTotalBalance');
        console.log(res.data);
        this.total_balance = res.data.total_balance;
        //this.expiration_date_milis = res.data.expiration_date_milis;
        this.days_to_expiration = this.getDaysToExpiration(res.data.expiration_date_milis);
            store.commit('Update_accountTypeState', res.data.account_type);
      });


    },

    getResumeViews() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/events/get?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        console.log('inside getResumeViews');
        console.log(res.data);
        this.numberOfResumesViewed = res.data.numberOfResumesViewed;
      });

    },


    getNumberOfResumes() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/resume-cart/number-of-resumes-in-cart?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        this.numberOfResumesInCart = res.data.numberOfResumesInCart;
      });


    },





    checkTokenValidity() {


      this.axios({
        method: 'get',
        url: this.$hostname + '/security-check?access_token=' + store.state.token,
      }).then((res) => {
        console.log('inside checkTokenValidity');
        console.log(res.data);
        if (res.data==='success'){
          console.log('token is valid')

          this.tokenIsValid = 'yes';
          this.$router.push({ path: '/resume/'+this.resumeId });
        } else {
          this.tokenIsValid = 'no';
        }

      }).catch((err) => {
        console.error(err);
        this.tokenIsValid = 'no';
      });


    },




    getDaysToExpiration(exiration_date_milis) {
      console.log('insode getDaysToExpiration')
      var exiration_date = new Date(exiration_date_milis);
      var current_date = new Date();
      var diffInMs = Math.max(exiration_date - current_date,0);
      console.log(diffInMs)
      return Math.round(diffInMs / (1000 * 3600 * 24),0);
    },

    getNumberOfVacancies() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/prod/vacancy/get-count?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        console.log('inside getNumberOfVacancies');
        console.log(res.data);
        store.commit('Update_numberOfVacancies_State', res.data.vacancy_counter);
      });


    },



    beforeunloadHandler(e){
      this.$store.commit('update_session_status', 2);
    },
    unloadHandler(e){
      this.$store.dispatch('record_event',
          {'browser_var': this.$browserDetect,'axios_var': this.axios,'root_var':this.$hostname,'cookies_var':this.$cookies,
            'actionType': 'close_page', 'clickedObjectId': ''});
    },


    logOut() {


        let token = this.$cookies.get("access_token_skillrank")
        this.$cookies.remove("access_token_skillrank");
        this.$cookies.remove("username_skillrank");
        this.$cookies.remove("id_skillrank");
        this.$cookies.remove("authority_skillrank");
        //this.$cookies.remove("skillrank_anonymous_id");

        this.axios({
          method:'delete',
          url: '' + this.$hostname +'/oauth/revoke?access_token='+token,
        }).then( (res) => {

          console.log('revoke')

        }).catch((e) => {
          console.log('error')
        });
        this.$router.push({ path: '/login' });

        this.$store.commit('UPDATE_LOGGEDIN_STATE', 0);

        // console.log('inside logout');

    },



    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },


    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;
      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
  },
};
</script>

<template>
  <div>
    <!-- Navbar STart -->
    <header
        id="topnav"
        class="defaultscroll sticky"
        :class="{ 'bg-white': isWhiteNavbar === false }"
    >

        <div class="container">

          <router-link class="logo mr-3" to="/">
            <img src="/images/logo-skillrank.png"  />
          </router-link>
          <div class="buy-button">
            <div v-if="$store.state.isLoggedIn===0">

            <a
                href="/signup"
                class="btn btn-primary"
                :class="{

            }"
            >Зарегистрироваться</a>
          </div>
            </div>





          <h1>Умный поиск IT сотрудников</h1>


          <p class=" text-white-50">
            Платформа Skillrank агрегирует более 350.000 резюме из LinkedIn, CareerHabr, HH и других источников
          </p>


            </div>


    </header>
  </div>
</template>


<style lang="scss">


.active
{
  cursor: pointer;
}

</style>
