<script>
import {
  ArrowUpIcon,
  UserIcon,
  MailIcon,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  GithubIcon,
  YoutubeIcon,
  GitlabIcon,
  GiftIcon,
  HomeIcon,
  MapPinIcon,
  GlobeIcon,
  ServerIcon,
  PhoneIcon,
  BookIcon,
  MessageCircleIcon,
} from "vue-feather-icons";

import Navbar from "@/components/navbar_recommendation";
import Footer from "@/components/footer";
import store from "@/store/store";
import Pricing from "@/components/pricing_rub_recommendation";

/**
 * Page-job-candidate component
 */
export default {
  data() {
    return {

      showPdfAlert: false,

      showButtonAddToCart: true,

      input2sql: {
        resume_id: 1,
        user_id: 1,
        resumes: [],
      },

      pricingDataMonth: [
        {
          title: "Пробный дневной доступ сразу после регистрации",
          price: 0,
          period: "1 день",
          feature: [

          ],
          button: "Получить",
          isBest: false,
        },
        {
          title: "Полный 3-х дневный доступ к Платформе",
          price: 250,
          quantity: 1,
          period: "3 дня",
          feature: [

          ],
          button: "Купить",
          isBest: false,
        },
        {
          title: "Недельный доступ к Платформе и интеграция",
          price: 400,
          quantity: 1,
          period: "7 дней",
          feature: [

          ],
          button: "Купить",
          isBest: true,
        },
      ],

      pricingDataYear: [
        {
          title: "Пробный доступ",
          price: 0,
          period: "мес",
          feature: [
            "10 просмотров резюме",
            "5 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
          isBest: true,
        },
        {
          title: "Полный Доcтуп к Платформе",
          price: 5990,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
        {
          title: "Доcтуп и интеграция",
          price: 7490,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
          isBest: false,
        },
      ],


      resumeData: {},
      contactsData: {},
      resumeHighlight: {
        full_name: '',
        location_city: '',
        age: 0,
        experience_total:0,
        salary: 0,

        skills: [],
      },

      hover:false,
      saveResume: {
        user_id: 1,
        resume_id:1,
        vacancy_id:1,
        vacancy_name: '',
        funnel_id: 1,
      },

      allVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      activeVacancies: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],


      savedSearch: {name:'Базовый',id:0,type_id:0},

      vacancyNames: [],
      attachedVacancies: [],

      optionsSavedSearch: [
        {
          id: 0,
          name: "Дефолт",
        },
      ],
      input_save_search: {
        type_id: 1,
        user_id: 1,
        toDelete: false,
        name: '',
        id: 0,
      },
      input_save_vacancy: {
        type_id: 1,
        user_id: 1,
        vacancy_id:0,
        toDelete: false,
        name: '',
        id: 0,
        status: 0,
      },

    };
  },
  components: {
    Navbar,
    Pricing,

    Footer,
    ArrowUpIcon,
    UserIcon,
    MailIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon,
    LinkedinIcon,
    GithubIcon,
    YoutubeIcon,
    GitlabIcon,
    GiftIcon,
    HomeIcon,
    MapPinIcon,
    GlobeIcon,
    ServerIcon,
    PhoneIcon,
    BookIcon,
    MessageCircleIcon,
  },


  mounted() {

    //this.$store.commit('UPDATE_TOKEN', this.$cookies.get('access_token_skillrank'));
    //this.$store.commit('UPDATE_USERNAME', this.$cookies.get('username_skillrank'));

   // console.log('token '+store.state.token + " user " + store.state.user_id)

    this.getResumeDetails();
    /*
    this.getAllVacancies();

      this.getResumeDetails();

    this.updateResumeViews();
    this.getAttahcedVacancies();

    this.isPaid();

     */
  },

methods: {

  getAllVacancies() {

    this.input_save_vacancy.user_id = store.state.user_id;

    var url = '' + this.$hostname + '/prod/vacancy/get-list?access_token=' + store.state.token;
    console.log('inside get all jobs ' + this.input_save_vacancy + '  ' + url)

    this.axios({
      method: 'post',
      url: url,
      data: this.input_save_vacancy,
    }).then((res) => {
      this.allVacancies = res.data.arr;
      this.activeVacancies = this.allVacancies.filter(x => x.status === 'Активная');
      //this.archiveVacancies = this.allVacancies.filter(x => x.status === 'Архивная');

      console.log(this.allVacancies);
      console.log(this.activeVacancies);
      /*
        if (this.allVacancies.length) {
          this.savedSearch = this.allVacancies[0];
        }
      */
    });

  },


  saveResumeForLater() {
    this.input2sql.resume_id = this.$route.params.resume_id;
    this.input2sql.user_id = store.state.user_id;

    if(this.contactsData.isSaved){
      this.input2sql.isSaved = 1;
    } else{
      this.input2sql.isSaved = 0;
    }


    this.input2sql.save_search_name = this.savedSearch.name;
    this.input2sql.save_search_id = this.savedSearch.id;



    this.axios({
      method: 'post',
      url:
          '' +
          this.$hostname +
          '/prod/resume/save-resume-for-later?access_token=' +
          this.$store.state.token,
      data: this.input2sql,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then((res) => {
      if (res.data.changedStatus){
        if (this.contactsData.isSaved===false) {
          this.contactsData.isSaved=true;
        } else {
          this.contactsData.isSaved=false;
        }

      }
    });


  },



  generateReport () {
    this.$refs.html2Pdf.generatePdf()
  },


  getAttahcedVacancies() {
    this.input2sql.user_id = store.state.user_id;
    this.input2sql.resume_id = this.$route.params.resume_id;
    console.log('inside getAttahcedVacancies')

    this.axios({
      method: 'post',
      url:
          '' +
          this.$hostname +
          '/prod/vacancy/get-vacancies-associated-with-resume?access_token=' + store.state.token,
      data: this.input2sql,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then((res) => {
      this.attachedVacancies = res.data.vacancies;
    });

  },


  saveResumeInVacancy(i){
    console.log('inside saveResumeInVacancy')
    this.saveResume.resume_id = this.$route.params.resume_id;
    this.saveResume.user_id = store.state.user_id;

    this.saveResume.vacancy_name = this.activeVacancies[i].vacancy_name;
    this.saveResume.vacancy_id = this.activeVacancies[i].vacancy_id;

    if (this.activeVacancies[i].funnel.length>0) {
      this.saveResume.funnel_id = this.activeVacancies[i].funnel[0].min_funnel_index;
    }
    this.resumeHighlight.full_name = this.contactsData.full_name;
    this.resumeHighlight.age = this.contactsData.age;
    this.resumeHighlight.experience_total = this.contactsData.experience_total;
    this.resumeHighlight.salary = this.contactsData.salary;
    this.resumeHighlight.skills = this.resumeData.hardSkills
    this.saveResume.resumeHighlight = this.resumeHighlight;

    console.log(this.saveResume);
    this.axios({
      method: 'post',
      url:
          '' +
          this.$hostname + '/prod/vacancy/save-resume?access_token=' + store.state.token,
      data: this.saveResume,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then((res) => {
      if (res.data.changedStatus){
        this.getAttahcedVacancies();
      }
    });

  },

  removeResumeFromVacancy(i){

    console.log('inside removeResumeFromVacancy')
    this.saveResume.resume_id = this.$route.params.resume_id;
    this.saveResume.user_id = store.state.user_id;

    this.saveResume.vacancy_name = this.attachedVacancies[i].vacancy_name;
    this.saveResume.vacancy_id = this.attachedVacancies[i].vacancy_id;

    this.axios({
      method: 'post',
      url:  this.$hostname + '/prod/vacancy/delete-resume?access_token=' + store.state.token,
      data: this.saveResume,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then((res) => {
      if (res.data.changedStatus){
        this.getAttahcedVacancies();
      }
    });

  },



  getResumeDetails() {

    this.input2sql.user_id = store.state.user_id;
    this.input2sql.resume_id = this.$route.params.resume_id;
    console.log('inside mounted resume')

    this.axios({
      method: 'post',
      url:
          '' +
          this.$hostname +
          //'/prod/resume-recommendation/get-resume' ,
          '/resume-weaviate-public/get-body',
      data: this.input2sql,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then((res) => {
      this.resumeData = res.data;
      console.log('resume')
      console.log(res.data);
    });
    /*
    this.axios({
      method: 'post',
      url:
          '' +
          this.$hostname +
          '/prod/resume-recommendation/get-resume-contacts',
      data: this.input2sql,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then((res) => {
      this.contactsData = res.data;
      console.log('contacts')
      console.log(this.contactsData);
    });
    */

  },

  updateResumeViews() {

    this.input2sql.user_id = store.state.user_id;
    this.input2sql.resume_id = this.$route.params.resume_id;
    console.log('inside mounted resume')


    this.axios({
      method: 'post',
      url:this.$hostname + '/events/update-opened-resume?access_token=' + store.state.token,
      data: this.input2sql,
    }).then((res) => {
      if(res.data.status==='ok') {
        this.axios({
          method: 'post',
          url:this.$hostname + '/events/get?access_token=' + store.state.token,
          data: this.input2sql,
        }).then((res) => {
          this.$cookies.set('numberOfResumesViewed', res.data.numberOfResumesViewed);
        });
        }
      console.log('event resume')
      console.log(res.data);
    });
  },


  addToCart() {

    this.input2sql.user_id = store.state.user_id;
    this.input2sql.resumes = [];
    this.input2sql.resumes.push({'resume_id': this.$route.params.resume_id,
                                    'resume_fullname': this.contactsData.full_name, 'resume_headline': this.contactsData.resume_headline});

    this.axios({
      method: 'post',
      url:this.$hostname + '/resume-cart/add-resumes?access_token=' + store.state.token,
      data: this.input2sql,
    }).then((res) => {
      if(res.data.status==='ok') {
        console.log('event addToCart')
        console.log(res.data);
      }

    });
  },

  isPaid() {

    this.input2sql.user_id = store.state.user_id;
    this.input2sql.resumes.push({'resume_id': this.$route.params.resume_id,
      'resume_fullname': this.contactsData.full_name, 'resume_headline': this.contactsData.resume_headline});

    this.axios({
      method: 'post',
      url:this.$hostname + '/resume-cart/check-resume-paid?access_token=' + store.state.token,
      data: this.input2sql,
    }).then((res) => {
      if(res.data.status==='ok') {
        console.log('isPaid')
        console.log(res.data);

        this.showButtonAddToCart = res.data.showButtonAddToCart;

      }

    });
  },



}



};


</script>

<template>
  <div>
    <Navbar :nav-light="true" :resume-id="$route.params.resume_id" />

    <!--<section class="bg-half-260 d-table w-100">
    <section class="bg-half-260 w-100">

    </section>
    -->
    <!--<div class="bg-overlay"></div>-->


    <!-- Candidate Detail Start -->
    <section class="section">


      <div class="container">
        <div class="row">
          <div class="col-lg-12">


            <b-alert v-if="showPdfAlert" show dismissible variant="info">
              Для выгрузки в <strong>pdf</strong> нажмите клавиши ctnrl+p и выберите печать в pdf-файл или сохранить страницу в pdf.
            </b-alert>

            <!--
            <div>

              <Pricing :pricingDataMonth="pricingDataMonth" />
            </div>-->





            <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
              <div class="card-body">
                <div class="row align-items-center">
                  <div class="col-lg-2 col-md-3 text-md-left text-center">
                    <img :src="resumeData.avatar" class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                  </div>
                  <!--end col-->

                  <div class="col-lg-10 col-md-9">
                    <div class="row align-items-end">
                      <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                        <h3 class="title mb-0">{{ contactsData.full_name }}</h3>
                        <small class="text-muted h6 mr-2">{{contactsData.resume_headline}}</small>
                        <!--
                        <ul class="list-inline mb-0 mt-3">
                          <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted" title="Instagram">
                            <instagram-icon class="fea icon-sm mr-2"></instagram-icon>krista_joseph
                          </a></li>
                          <li class="list-inline-item">
                            <router-link to="/account-setting" class="rounded text-muted" v-b-tooltip.hover title="Settings">
                              <linkedin-icon class="fea icon-sm fea-social mr-2"></linkedin-icon>
                              Krista Joseph
                            </router-link>
                          </li>
                        </ul>
                        -->
                      </div>
                      <!--end col-->
                      <!--
                      <div class="col-md-5 text-md-right text-center">
                        <ul class="list-unstyled social-icon social mb-0 mt-4">
                          <li class="list-inline-item"><a href="javascript:void(0)" class="rounded" v-b-tooltip.hover title="Add Friend">
                            <user-plus-icon class="fea icon-sm fea-social"></user-plus-icon>
                          </a></li>
                          <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" v-b-tooltip.hover title="Messages">
                            <message-circle-icon class="fea icon-sm fea-social"></message-circle-icon>
                          </a></li>
                          <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" v-b-tooltip.hover title="Notifications">
                            <bell-icon class="fea icon-sm fea-social"></bell-icon>
                          </a></li>
                          <li class="list-inline-item ml-1">
                            <router-link to="/account-setting" class="rounded" v-b-tooltip.hover title="Settings">
                              <tool-icon class="fea icon-sm fea-social"></tool-icon>
                            </router-link>
                          </li>
                        </ul>

                      </div>

                      -->
                      <!--end col-->


                    </div>
                    </div>
                    <!--end row-->
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->








      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-5 col-12">
            <!--<div class="card job-profile shadow border-0">

              <div class="text-center py-5 border-bottom rounded-top">
                <img
                  src="images/client/01.jpg"
                  class="avatar avatar-medium mx-auto rounded-circle shadow d-block"
                  alt=""
                />
                <h5 class="mt-3 mb-0">Thomas Brewer</h5>
                <p class="text-muted mb-0">Senior Web Developer</p>

                </div>
              -->
              <div class="card-body">
                <ul class="list-unstyled">
                  <!--
                  <li class="h6">
                    <mail-icon class="fea icon-sm text-warning mr-2"></mail-icon>
                    <span class="text-muted">Email :</span> thomas@mail.com
                  </li>
                  -->
                  <li class="h6">
                    Для просмотра контактов зарегистрируйтесь
                  </li>
                  <li class="h6">
                    <gift-icon class="fea icon-sm text-warning mr-2"></gift-icon
                    ><span class="text-muted">Возраст :</span> {{ contactsData.age }}
                  </li>
                  <li class="h6">
                    <home-icon class="fea icon-sm text-warning mr-2"></home-icon
                    ><span class="text-muted">Опыт :</span> {{ contactsData.experience_total }}
                  </li>
                  <li class="h6">
                    <map-pin-icon
                      class="fea icon-sm text-warning mr-2"
                    ></map-pin-icon
                    ><span class="text-muted">Город :</span> {{ contactsData.location_city }}
                  </li>
                  <li class="h6">
                    <globe-icon
                      class="fea icon-sm text-warning mr-2"
                    ></globe-icon
                    ><span class="text-muted">Страна :</span> {{ contactsData.location_country }}
                  </li>
                  <li class="h6" v-for="contactsD in contactsData.contacts">
                    <mail-icon class="fea icon-sm text-warning mr-2"></mail-icon>
                    <span class="text-muted">{{ contactsD.type }} :</span> {{ contactsD.value }}
                  </li>

                  <div class="features mt-5" v-if="$store.state.account_type==='demo' && showButtonAddToCart === false">
                    <div class="image position-relative d-inline-block">
                      <i class="uil uil-padlock h1 text-primary"></i>
                    </div>

                    <div class="content mt-2">
                      <h6>Открыть контакты</h6>
                      <a @click="addToCart" class="btn btn-outline-info rounded">
                        <i class="uil uil-shopping-cart"></i>
                        В корзину
                      </a>
                    </div>
                  </div>

                  <!--
                  <li class="h6">
                    <phone-icon
                      class="fea icon-sm text-warning mr-2"
                    ></phone-icon
                    ><span class="text-muted">Mobile :</span> (+125) 1542-8452
                  </li>
                  -->
                  <!--
                  <li>
                    <ul class="list-unstyled social-icon mb-0 mt-4">
                      <li class="list-inline-item">
                        <a href="javascript:void(0)" class="rounded">
                          <facebook-icon
                            class="fea icon-sm fea-social"
                          ></facebook-icon>
                        </a>
                      </li>
                      <li class="list-inline-item ml-1">
                        <a href="javascript:void(0)" class="rounded">
                          <instagram-icon
                            class="fea icon-sm fea-social"
                          ></instagram-icon>
                        </a>
                      </li>
                      <li class="list-inline-item ml-1">
                        <a href="javascript:void(0)" class="rounded">
                          <twitter-icon
                            class="fea icon-sm fea-social"
                          ></twitter-icon>
                        </a>
                      </li>
                      <li class="list-inline-item ml-1">
                        <a href="javascript:void(0)" class="rounded">
                          <linkedin-icon
                            class="fea icon-sm fea-social"
                          ></linkedin-icon>
                        </a>
                      </li>
                      <li class="list-inline-item ml-1">
                        <a href="javascript:void(0)" class="rounded">
                          <github-icon
                            class="fea icon-sm fea-social"
                          ></github-icon>
                        </a>
                      </li>
                      <li class="list-inline-item ml-1">
                        <a href="javascript:void(0)" class="rounded">
                          <youtube-icon
                            class="fea icon-sm fea-social"
                          ></youtube-icon>
                        </a>
                      </li>
                      <li class="list-inline-item ml-1">
                        <a href="javascript:void(0)" class="rounded">
                          <gitlab-icon
                            class="fea icon-sm fea-social"
                          ></gitlab-icon>
                        </a>
                      </li>
                    </ul>
                  </li>
                  -->


                </ul>
                <!--
                <a
                  href="javascipt:void(0)"
                  data-toggle="modal"
                  data-target="#Contactme"
                  class="btn btn-block btn-primary"
                  ><i class="mdi mdi-email"></i> Contact Me</a
                >

                <a href="javascript:void(0)" class="btn btn-primary mr-2"
                ><i class="mdi mdi-account-check"></i> Hire me</a
                >
                <a href="javascript:void(0)" class="btn btn-outline-primary"
                ><i class="mdi mdi-printer"></i> Print CV</a
                >
-->

                <b-dropdown class="btn-group mr-2 mt-2" variant="primary" text="Сохранить">
                  <b-dropdown-item v-for="(vacancy,i) in activeVacancies" @click="saveResumeInVacancy(i)">
                    {{ vacancy.vacancy_name }}
                  </b-dropdown-item>

                </b-dropdown>

                <b-dropdown class="btn-group mr-2 mt-2" variant="secondary" text="Выгрузить">
                  <b-dropdown-item @click="showPdfAlert = true">Pdf</b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item><i class="uil uil-lock"></i>Huntflow</b-dropdown-item>
                  <b-dropdown-item><i class="uil uil-lock"></i>Potok</b-dropdown-item>
                  <b-dropdown-item><i class="uil uil-lock"></i>Friend.Work</b-dropdown-item>
                  <b-dropdown-item><i class="uil uil-lock"></i>Talantix</b-dropdown-item>
                </b-dropdown>

              </div>
            <h6 v-if="attachedVacancies.length>0">Резюме сохранено:</h6>
            <div class="row list-inline justify-content-start" v-if="attachedVacancies.length>0">

              <a class="btn btn-secondary list-inline-item m-2" v-for="(vacancy,ix) in attachedVacancies"> {{vacancy.vacancy_name}}
                <span class="badge badge-pill badge-secondary" @click="removeResumeFromVacancy(ix)">X</span></a>
            </div>


          </div>
          <!--end col-->

          <!--<div class="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">-->
          <div class="col-lg-8 col-md-7 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <!--<div class="ml-lg-4">-->
            <div>
              <h4>Обо мне </h4>
              <!--
              <p class="text-muted">
                Obviously I'M Web Developer. Web Developer with over 3 years of
                experience. Experienced with all stages of the development cycle
                for dynamic web projects. The as opposed to using 'Content here,
                content here', making it look like readable English.
              </p>
              <p class="text-muted mb-0">
                Data Structures and Algorithms are the heart of programming.
                Initially most of the developers do not realize its importance
                but when you will start your career in software development, you
                will find your code is either taking too much time or taking too
                much space.
              </p>
              -->

              <div v-html="resumeData.about" class="text-muted"></div>

              <!-- Skills Start -->
              <h4 class="mt-lg-5 mt-4">Навыки </h4>

                <div class="row">
                  <a href="#" class="btn btn-pills btn-info m-2 " v-for="skill in resumeData.hardSkills"> {{ skill }} </a>
                  <!--
                  <a href="#" class="btn btn-pills btn-info mr-2"> HTML </a>
                  <a href="#" class="btn btn-pills btn-info mr-2"> PHP </a>
                  -->
                </div>

              <h4 class="mt-lg-5 mt-4">Профессиональный опыт </h4>
              <div class="row">
                <div class="col-lg-12 mt-4 pt-2" v-for="job in resumeData.jobs">
                  <div class="media">
                    <div class="company-logo text-muted h6 mr-3 text-center">
                      <!--<img
                        src="images/job/Codepen.svg"
                        class="avatar avatar-md-sm mx-auto d-block mb-2"
                        alt=""
                      />-->
                      {{job.start_date}}-{{job.end_date.substring(2, 4)}}
                    </div>
                    <div class="media-body">
                      <h5 class="title mb-0">{{ job.position }}</h5>
                      <small class="text-muted company-university"
                        >{{ job.company_name }} - {{ job.location_city }}, {{ job.location_country }}
                      </small>
                      <p class="text-muted mt-2 mb-0" v-html="job.description">
                      </p>
                    </div>
                  </div>
                </div>
                <!--end col-->

              </div>

              <h4 class="mt-lg-5 mt-4">Образование </h4>
              <div class="row">
                <div class="col-lg-12 mt-4 pt-2" v-for="university in resumeData.universities">
                  <div class="media">
                    <div class="company-logo text-muted h6 mr-3 text-center">
                      <!--<img
                          src="images/job/Codepen.svg"
                          class="avatar avatar-md-sm mx-auto d-block mb-2"
                          alt=""
                      />-->
                      {{university.start_date}}-{{university.end_date.substring(2, 4)}}
                    </div>
                    <div class="media-body">
                      <h5 class="title mb-0">{{ university.university_name }}</h5>
                      <small class="text-muted company-university"
                      >{{ university.faculty_name }} - {{ university.location_city }}, {{ university.location_country }}
                      </small>
                      <p class="text-muted mt-2 mb-0" v-html="university.description">
                      </p>
                    </div>
                  </div>
                </div>
                <!--end col-->

              </div>

              <!--end row-->
              <!-- Experience End -->

              <!--
              <div class="mt-4 pt-2">
                <a href="javascript:void(0)" class="btn btn-primary mr-2"
                  ><i class="mdi mdi-account-check"></i> Hire me</a
                >
                <a href="javascript:void(0)" class="btn btn-outline-primary"
                  ><i class="mdi mdi-printer"></i> Print CV</a
                >
              </div>
              -->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--enn row-->
      </div>
      <!--end container-->
    </section>



    <Footer />
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
